<script setup lang="ts">
import UserAvatar from '../components/UserAvatar.vue'

const mainStore = useMainStore()
</script>

<template>
  <UserAvatar :username="mainStore?.userName" :avatar="mainStore?.userAvatar">
    <slot />
  </UserAvatar>
</template>
